<template>
  <div>
    <page-container :title="breadcrumb" :breadcrumb="breadcrumb">
      <div class="bg-white">
        <div class="main-container" style="margin-top: -15px">
          <div class="title">平台参数配置</div>
        </div>
      </div>
      <div class="container">
        <div class="table-btns" style="margin-top: 2%">
          <a-button type="primary" size="large" @click="getOrSet" style="margin-left: 80%">
            {{status ?'下发当前参数':'获取最新参数' }}</a-button>
          <a-icon type="redo" class="after-btn table-refresh" @click="getParams" style="margin-left: 1%;font-size: 20px"></a-icon>
        </div>
        <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns"  :label-col="{span:12}" :wrapper-col="{span:12}" style="margin-top: 1%">
          <a-divider>报警参数</a-divider>
          <a-form-model-item label="消防主机485对接故障/事件进入小三角报警使能">
            <a-select v-model="platParams.eventable" placeholder="1使能，0禁用，默认使能" style="width: 300px">
              <a-select-option :value="1">使能</a-select-option>
              <a-select-option :value="0">禁用</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="消防主机485对接故障/事件进入测试页面使能">
            <a-select v-model="platParams.ulakelabable" placeholder="1使能，0禁用，默认使能" style="width: 300px">
              <a-select-option :value="1">使能</a-select-option>
              <a-select-option :value="0">禁用</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="机房有人事件判断时长">
            <a-input v-model="platParams.machine_room_person_time" suffix="秒" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-divider>流媒体参数</a-divider>
<!--          <a-form-model-item label="MQTT地址">-->
<!--            <a-input v-model="platParams.mqtt_url" style="width: 300px"></a-input>-->
<!--          </a-form-model-item>-->
          <a-form-model-item label="流媒体查询IP列表">
            <a-input v-model="platParams.stream_ip_list" style="width: 300px"></a-input>
          </a-form-model-item>
<!--          <a-form-model-item label="流媒体推流域名">-->
<!--            <a-input v-model="platParams.push_stream_ip_list" style="width: 300px"></a-input>-->
<!--          </a-form-model-item>-->
<!--          <a-form-model-item label="软终端流媒体推流端口">-->
<!--            <a-input v-model="platParams.push_stream_port_soft" style="width: 300px"></a-input>-->
<!--          </a-form-model-item>-->
<!--          <a-form-model-item label="硬终端流媒体推流端口">-->
<!--            <a-input v-model="platParams.push_stream_port_hard" style="width: 300px"></a-input>-->
<!--          </a-form-model-item>-->
          <a-form-model-item label="平台视频直播播放最长时长">
            <a-input v-model="platParams.videotiming" suffix="分钟" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-divider>场景参数</a-divider>
          <a-form-model-item label="场景运行数据进入数据库时间间隔">
            <a-input v-model="platParams.scene_run_data_interval" suffix="秒" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-divider>报事报修参数</a-divider>
          <a-form-model-item label="报事报修接单超时时间">
            <a-input v-model="platParams.receiving_orders_timeout" suffix="分钟" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-divider>我管我家参数</a-divider>
          <a-form-model-item label="履约报告中‘项目情况’和'物业服务工作'数据同步配置" style="width: 60%">
          </a-form-model-item>
          <a-form-model-item label="同步时间间隔配置（单位：小时）">
            <a-input v-model="platParams.ihome_data_rsync_interval" suffix="小时" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="同步数据跨度配置（单位：天）">
            <a-input v-model="platParams.ihome_data_rsync_span" suffix="天" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-divider>平台自检日志参数</a-divider>
          <a-form-model-item label="平台自检日志保存时间">
            <a-input v-model="platParams.saved_time" suffix="天" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="TF卡异常发送人员手机号">
            <a-input v-model="platParams.tf_abnormal_phone" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="CPU平均使用率阈值">
            <a-input v-model="platParams.cpu_avr_rate" suffix="%" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="CPU最大使用率阈值">
            <a-input v-model="platParams.cpu_max_rate" suffix="%" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="CPU平均使用温度阈值">
            <a-input v-model="platParams.cpu_avr_temp" suffix="℃" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="CPU最大使用温度阈值">
            <a-input v-model="platParams.cpu_max_temp" suffix="℃" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="磁盘平均使用率阈值">
            <a-input v-model="platParams.disk_avr_rate" suffix="%" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="磁盘最大使用率阈值">
            <a-input v-model="platParams.disk_max_rate" suffix="%" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="IO平均使用率阈值">
            <a-input v-model="platParams.io_avr_rate" suffix="%" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="IO最大使用率阈值">
            <a-input v-model="platParams.io_max_rate" suffix="%" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="内存平均使用率阈值">
            <a-input v-model="platParams.memory_avr_rate" suffix="%" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="内存最大使用率阈值">
            <a-input v-model="platParams.memory_max_rate" suffix="%" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="上行网络平均速度阈值">
            <a-input v-model="platParams.up_net_avr_rate" suffix="Mbps" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="上行网络最大速度阈值">
            <a-input v-model="platParams.up_net_max_rate" suffix="Mbps" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="下行网络平均速度阈值">
            <a-input v-model="platParams.down_net_avr_rate" suffix="Mbps" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="下行网络最大速度阈值">
            <a-input v-model="platParams.down_net_max_rate" suffix="Mbps" style="width: 300px"></a-input>
          </a-form-model-item>
        </a-form-model>
      </div>
    </page-container>
  </div>
</template>
<script>

import {findFlatParams, modifyFlatParams} from "A/xtpz";

export default {
  name: "functionparams",
  data() {
    return {
      breadcrumb: [
        {
          name: '系统配置',
          path: ''
        },
        {
          name: '高级设置',
          path: ''
        },
        {
          name: '平台参数',
          path: ''
        },
        {
          name: '平台参数配置',
          path: ''
        },
      ],
      platParams:{
        paramsid:'',
        //消防主机485对接故障/事件进入小三角报警使能
        eventable:'',
        //消防主机485对接故障/事件进入测试页面使能
        ulakelabable:'',
        //平台视频播放最长时长
        videotiming:'',
        //流媒体查询IP列表
        stream_ip_list:'',
        //mqtt域名
        mqtt_url:'',
        //流媒体推流域名
        push_stream_ip_list:'',
        //软终端流媒体推流端口
        push_stream_port_soft:'',
        //硬终端流媒体推流端口
        push_stream_port_hard:'',
        //机房有人事件判断时间
        machine_room_person_time:'',
        //场景运行数据进入数据库时间间隔
        scene_run_data_interval:'',
        //报事报修接单超时时间
        receiving_orders_timeout:'',
        //平台自检日志保存时间
        saved_time:'',
        //TF卡异常发送人员手机号
        tf_abnormal_phone:'',
        //CPU平均使用率阈值
        cpu_avr_rate:'',
        //CPU最大使用率阈值
        cpu_max_rate:'',
        //CPU平均使用温度阈值
        cpu_avr_temp:'',
        //CPU最大使用温度阈值
        cpu_max_temp:'',
        //磁盘平均使用率阈值
        disk_avr_rate:'',
        //磁盘最大使用率阈值
        disk_max_rate:'',
        //IO平均使用率阈值
        io_avr_rate:'',
        //IO最大使用率阈值
        io_max_rate:'',
        //内存平均使用率阈值
        memory_avr_rate:'',
        //内存最大使用率阈值
        memory_max_rate:'',
        //上行网络平均速度阈值
        up_net_avr_rate:'',
        //上行网络最大速度阈值
        up_net_max_rate:'',
        //下行网络平均速度阈值
        down_net_avr_rate:'',
        //下行网络最大速度阈值
        down_net_max_rate:'',
        //我管我家项目情况信息后台同步时间间隔
        ihome_data_rsync_interval:'',
        //同步数据跨度配置
        ihome_data_rsync_span:""
      },
      status:false,
    }
  },
  created() {
    // this.init();
  },
  methods: {
    init() {
      // this.getParams()
    },
    getParams(){
      let params={
      }
      findFlatParams(params).then(res=>{
        if(res&&res.returncode=="0"){
          this.platParams=res.item[0]
        }
      })
    },
    setParams(){
      let params=this.platParams
      modifyFlatParams(params).then(res=>{
        if(res&&res.returncode=="0"){
          this.$message.success("参数下发成功")
          this.platParams.eventable=Number(this.platParams.eventable)
          this.platParams.ulakelabable=Number(this.platParams.ulakelabable)
          this.platParams.videotiming=Number(this.platParams.videotiming)
        }
      })
    },
    getOrSet(){
      if(this.status){
        this.setParams();
        this.status=false;
      }else{
        this.getParams();
        this.status=true
      }
    },
  }
}
</script>
<style scoped>
.bg-white {
  background-color: #fff;
}
.container{
  background-color: #fff;
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 1%;
  flex-grow: 1;
  overflow: auto;
  height: 730px;
}
.title {
  font-weight: bold;
  font-size: 20px;
  padding: 5px 20px;
}
.main-container {
  display: flex;
  padding: 10px;
}

</style>